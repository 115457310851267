import type { NextPage } from 'next';
import Image from 'next/image';

import podsLogo from 'public/images/pods-logo-2.png';
import Page from 'components/wrappers/Page';

const Custom404: NextPage = () => {
  return (
    <Page className="Custom404">
      <div className="fixed top-0 left-0 right-0 bottom-0 bg-stone-900 flex flex-col items-center justify-center">
        <div className="Login__logo relative w-[188px] mb-12">
          <Image src={podsLogo} alt="PODS" />
        </div>
        <h1 className="font-bold text-white text-2xl mb-6">404</h1>
        <div className="font-bold text-white">The page you are looking for is not here</div>
      </div>
    </Page>
  );
};

export default Custom404;
